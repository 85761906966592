import React from "react";
import { AllRoute } from "./Components/allRoute/allRoutes";

function App() {
  return (
    <div>
      <AllRoute />
    </div>
  );
}

export default App;
